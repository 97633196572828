import { defineStore } from "pinia";
import type { IApplication } from "@/models/applications";
import type {
  IFlowRecord,
  IWorkflow,
  IWorkflowStage
} from "@/models/workflows";

interface UsePiPState {
  activePiPDeal: IApplication | null;
  activePiPWorkflow: IWorkflow | null;
  activePiPFlows: IFlowRecord[];
  activePiPStages: IWorkflowStage[];
}
const getDefaultState = (): UsePiPState => ({
  activePiPDeal: null,
  activePiPWorkflow: null,
  activePiPFlows: [],
  activePiPStages: []
});

const usePiPStore = defineStore("pip", {
  state: getDefaultState
});

export default usePiPStore;
