import type { CallLog } from "@/models/communicationLogs";
import { defineStore } from "pinia";

interface UseCallsState {
  callDialog: CallLog | null;
}

const getDefaultState = (): UseCallsState => ({
  callDialog: null
});

const useCallsStore = defineStore("calls", {
  state: getDefaultState
});

export default useCallsStore;
