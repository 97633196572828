<template>
  <communication-log-call-dialog
    v-if="callDialog && type === PipType.Call"
    ref="communicationLogCallDialogRef"
    :value="callDialog"
    @close-dialog="closePiPModal"
  />
  <div v-else-if="type === PipType.Note" ref="communicationLogNoteRef">
    <notes-content />
  </div>
</template>
<script setup lang="ts">
import CommunicationLogCallDialog from "@/components/communicationLogs/communication/CommunicationLogCallDialog.vue";
import NotesContent from "@/components/deals/NotesContent.vue";
import { NoteState } from "@/enums/notes";

import { PipType } from "@/enums/pip";
import {
  DEFAULT_CALL_MODAL_OPTIONS,
  DEFAULT_DEAL_NOTES_OPTIONS
} from "@/helpers/constants/deals";
import { useEmitter } from "@/hooks/common";
import { useNotification } from "@/hooks/notifications";
import { useLocalStorageSetting } from "@/hooks/options";

import { usePiPView } from "@/hooks/pip";
import useCallsStore from "@/stores/calls";
import useNotesStore from "@/stores/notes";
import usePiPStore from "@/stores/pip";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import apiService from "@/services/modules/workflows";

const { callDialog } = storeToRefs(useCallsStore());
const { isNotePiPModeActivated, activeNoteBusinessDealId } =
  storeToRefs(useNotesStore());
const { activePiPDeal, activePiPFlows, activePiPStages, activePiPWorkflow } =
  storeToRefs(usePiPStore());
const { initPiPView } = usePiPView();
const { showMessage } = useNotification();
const emitter = useEmitter();
const { t } = useI18n();

const closeNoteState = ref<NoteState>(NoteState.Closed);

const { type } = defineProps<{
  type: PipType;
}>();

const emit = defineEmits<{
  "pip:hide": [];
}>();

const callOptions = useLocalStorageSetting(
  "callModalOptions",
  DEFAULT_CALL_MODAL_OPTIONS
);

const noteOptions = useLocalStorageSetting(
  "dealNotesOptions",
  DEFAULT_DEAL_NOTES_OPTIONS
);

const unsetActivePiP = () => {
  isNotePiPModeActivated.value = false;
  activePiPDeal.value = null;
  activePiPWorkflow.value = null;
  activePiPFlows.value = [];
  activePiPStages.value = [];
};

emitter.on("CLOSE_PIP_VIEW", async () => {
  if (type === PipType.Note) {
    closeNoteState.value = NoteState.Draggable;
    unsetActivePiP();
  }
  window.documentPictureInPicture?.window.close();
  emit("pip:hide");
});

const communicationLogCallDialogRef = ref<InstanceType<
  typeof CommunicationLogCallDialog
> | null>(null);

const communicationLogNoteRef = ref<InstanceType<typeof HTMLDivElement> | null>(
  null
);

const PipParams = computed(() => ({
  [PipType.Call]: {
    htmlPiPElement: communicationLogCallDialogRef.value?.resizableElement,
    width: callOptions.value.width,
    height: callOptions.value.height,
    actionOnClose: closePiPModal
  },
  [PipType.Note]: {
    htmlPiPElement: communicationLogNoteRef.value,
    width: noteOptions.value.width,
    height: noteOptions.value.height,
    actionOnClose: closePiPModal
  }
}));

const closePiPModal = () => {
  window.documentPictureInPicture?.window.close();
  if (type === PipType.Note) {
    noteOptions.value = {
      ...noteOptions.value,
      state: closeNoteState.value
    };
    unsetActivePiP();
  } else {
    callDialog.value = null;
  }
  emit("pip:hide");
};

window.documentPictureInPicture?.addEventListener("enter", () => {
  if (type === PipType.Note) {
    isNotePiPModeActivated.value = true;
  }
});

onMounted(async () => {
  // Initially, type "Application Note" is selected
  activeNoteBusinessDealId.value = null;
  const canShowPip = await initPiPView(PipParams.value[type]);

  if (canShowPip) {
    return;
  }

  closeNoteState.value = NoteState.Draggable;
  closePiPModal();
  showMessage(t("COMMON.PIP.ERROR"), "warning");
});

watch(activePiPDeal, async (deal) => {
  if (deal) {
    activePiPWorkflow.value = await apiService.getWorkflowSnapshot(deal.id);
    activePiPFlows.value = activePiPWorkflow.value?.content?.flow || [];
    activePiPStages.value = activePiPWorkflow.value?.content?.stages || [];
  }
});
</script>
