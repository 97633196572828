import type { Nullish } from "@/models/common";
import { computed } from "vue";

export const usePiPView = () => {
  const defaultWidth = 500;
  const defaultHeight = 200;
  const isPiPAvailable = computed(() => "documentPictureInPicture" in window);

  const initPiPView = async (params: {
    htmlPiPElement: HTMLElement | Nullish;
    width?: number;
    height?: number;
    actionOnClose?: () => void;
  }) => {
    if (!isPiPAvailable.value || !params.htmlPiPElement) {
      return false;
    }

    const pipWindow = await window.documentPictureInPicture?.requestWindow({
      width: params.width ?? defaultWidth,
      height: params.height ?? defaultHeight
    });

    if (!pipWindow) {
      return false;
    }

    for (const styleSheet of document.styleSheets) {
      try {
        const cssRules = [...styleSheet.cssRules]
          .map((rule) => rule.cssText)
          .join("");
        const style = document.createElement("style");

        style.textContent = cssRules;
        pipWindow.document.head.appendChild(style);
      } catch (error) {
        return false;
      }
    }

    pipWindow.document.body.append(params.htmlPiPElement);
    params.htmlPiPElement.style.height = "100vh";
    const resizableModalWindow = document.querySelector(".resizable-component");
    pipWindow.addEventListener("pagehide", () => params.actionOnClose?.());

    if (resizableModalWindow) {
      resizableModalWindow.remove();
    }

    return true;
  };

  return { isPiPAvailable, initPiPView };
};
